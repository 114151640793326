import axios from "axios";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

export async function apiDatosCalculos(diccionario_datos) {
  console.log(diccionario_datos)
  const response = await axios.get(
    "https://oc43l24kjg.execute-api.us-east-1.amazonaws.com/monetizacion/MonetizacionV1",
    {
      params: {
        diccionario : diccionario_datos
      },
    }
  );
  console.log(response)
  
  return response
}

