import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, ListGroup, Spinner, Table, Col, Row } from "react-bootstrap";


import { countries } from '../../apis/countries';
import { tamanos } from '../../apis/tamanos_empresa';
import { periodicidades } from '../../apis/periodicidades';
import { diccionario_industrias } from '../../apis/industrias';
import { apiDatosCalculos } from '../../apis/api';

const DatosComparacionForm = () => {

  const [values, setValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigation = useNavigate();
  const paises = countries;
  const lista_tamanos = tamanos;
  const dic_industrias =  diccionario_industrias;
  const periodicidades_tareas = periodicidades;
  const[numberOfQuestions, setNumberOfQuestions] = useState(0);
  const[questions, setQuestions] = useState([]);
  const [pais, setPais] = useState('');
  const [industria, setIndustria] = useState('');
  const [industrias, setIndustrias] = useState([]);
  const [cargo, setCargo] = useState('');
  const [tamano, setTamano] = useState('');
  const [cargos, setCargos] = useState([]);
  const [salario, setSalario] = useState('');


  const onFormChange = (e, updatedAt) => {
    console.log(e.target.name)
    console.log(e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
    if(name === 'personas'){
      if(value > 0){
        const generateArrays= Array.from(Array(Number(value)).keys())
          setQuestions(generateArrays);
      } else {
        setQuestions([])
      }
    }

  };

 

  const onCountryChange = (e, updatedAt) => {
    console.log(e.target.name)
    console.log(e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    const pais = e.target.value;
    setValues({ ...values, [name]: value });
    setPais(pais);
    setIndustrias(Object.keys(dic_industrias[pais]))
  };

  const onIndustryChange = (e, updatedAt) => {
    console.log(e.target.name)
    console.log(e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    const industria = e.target.value;
    setValues({ ...values, [name]: value });
    setIndustria(industria);
    console.log(dic_industrias[pais][industria],industria)
    setCargos(Object.keys(dic_industrias[pais][industria]));
    console.log(cargos)
  };

  const onTamanoChange = (e, updatedAt) => {
    console.log(e.target.name)
    console.log(e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    const tamano = e.target.value;
    setValues({ ...values, [name]: value });
    setTamano(tamano);
    console.log(tamano)
  };

  const onCargoChange = (e, updatedAt) => {
    console.log(e.target.name)
    console.log(e.target.value)
    const name = e.target.name;
    const value = e.target.value;
    const cargo = e.target.value;
    setValues({ ...values, [name]: value });
    setCargo(cargo);

    const salario = dic_industrias[pais][industria][cargo][tamano];
    setSalario(salario);
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
  };


  const personQuestion = (question) =>{
    return (
      
      <Row htmlFor="numberOfQuestions" className="mb-l">
        <Col><label>Persona {question+1}</label></Col>
        {/*<Form.Group as={Col} controlId={"formCargo"+question}>
        <Form.Label>Cargo persona {question+1}*</Form.Label>
          <Form.Select 
          required
          name={"cargo_seleccionado_"+question}
          onChange={onCargoChange}
          >
          <option></option>
            {cargos.map((carg) => (

                <option key={carg+question} value={carg}>{carg}</option>
              ))
            }
          </Form.Select>
        </Form.Group>*/}

        <Form.Group as={Col} controlId={"formSalario"+question}>
        <Form.Label>Salario mensual*</Form.Label>
          <Form.Control
            name={"salario_seleccionado_"+question}
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder=""
            //defaultValue={salario}
          />
        </Form.Group>

        <Form.Group as={Col} controlId={"formVinculado"+question}>
        <Form.Label>¿Vinculado?*</Form.Label>
          <Form.Check
            name={"contrato_seleccionado_"+question}
            type="checkbox"
            onChange={onFormChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId={"formOptimista"+question}>
        <Form.Label>Horas optimista</Form.Label>
          <Form.Control
            name={"optimista_seleccionado_"+question}
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder=""
          />
        </Form.Group>

        <Form.Group as={Col} controlId={"formReal"+question}>
        <Form.Label>Horas promedio*</Form.Label>
          <Form.Control
            required
            name={"real_seleccionado_"+question}
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder=""
          />
        </Form.Group>

        <Form.Group as={Col} controlId={"formPesimista"+question}>
        <Form.Label>Horas pesimista</Form.Label>
          <Form.Control
            name={"pesimista_seleccionado_"+question}
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder=""
          />
        </Form.Group>
      </Row>
         
    );
  }



  const submitHandler = async (event) => {
    
    console.log(values)
    
    event.preventDefault();

    const allErrors = {};

    const isEmpty = Object.keys(allErrors).length === 0;

    var dict = {}; 

    dict['datos']={
        pais: values['pais_seleccionado'],
        tamano: values['tamano_seleccionado'],
        periodo: values['periodo_seleccionado'],
        personas: values['personas'],
        anos: values['anos_seleccionados'],
        inversion: values['inversion_seleccionada'] || 0,
        industria: values['industria_seleccionada']
      };


    let nombresAtributos = ['pais','tamano','periodo','personas', 'anos', 'inversion','cargo', 'salario', 'contrato', 'h_optimista', 'h_real', 'h_pesimista']
    let numPersonas = questions.length-1;
    console.log(numPersonas)
    let objetoPersonas = [];

    for (let i=0; i<= numPersonas; i++){
      let personaIn = {
        cargo: values['cargo_seleccionado_'+i],
        salario: values['salario_seleccionado_'+i],
        contrato: values['contrato_seleccionado_'+i],
        h_optimista: values['optimista_seleccionado_'+i],
        h_real: values['real_seleccionado_'+i],
        h_pesimista: values['pesimista_seleccionado_'+i]
      
    }
      objetoPersonas.push(personaIn)
    }
    
    dict['personas']=objetoPersonas;
    var resultados = {};
    await apiDatosCalculos(
      dict
      ).then((response) => (resultados = response.data));
      setValidated(true);
      navigation("/resultados", { state: resultados });
    console.log(dict)
  };

  return (

    <Form onSubmit={submitHandler} validated={validated}>
      <Row className="mb-f">
        <Form.Group as={Col} controlId="formCountry">
          <Form.Label>País*</Form.Label>
          <Form.Select 
            required
            name="pais_seleccionado"
            onChange={onCountryChange}
            >
          <option>Seleccionar país</option>
            {paises.map((country) => (
                <option key={country.nombre} value={country.valor}>{country.valor}</option>
              ))
            }
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formIndustria">
          <Form.Label>Industria*</Form.Label>
          <Form.Select 
            required
            name="industria_seleccionada"
            onChange={onIndustryChange}
            >
          <option>Seleccionar industria</option>
            {industrias.map((indus) => (
                <option key={indus} value={indus}>{indus}</option>
              ))
            }
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formSize">
          <Form.Label>Tamaño de la empresa*</Form.Label>
          <Form.Select 
            required
            name="tamano_seleccionado"
            onChange={onTamanoChange}>
          <option>Seleccionar tamaño de la empresa</option>
            {lista_tamanos.map((tamano) => (

                <option key={tamano.nombre} value={tamano.valor}>{tamano.nombre}</option>
              ))
            }
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-f">   
        <Form.Group as={Col} controlId="formPeriod">
          <Form.Label>Periodicidad con la que se realiza el proceso*</Form.Label>
          <Form.Select 
            required
            name="periodo_seleccionado"
            onChange={onFormChange}>
          <option>Seleccionar periodicidad del proceso</option>
            {periodicidades_tareas.map((periodicidad) => (

                <option key={periodicidad.nombre} value={periodicidad.nombre}>{periodicidad.nombre}</option>
              ))
            }
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formPersons">
          <Form.Label>Número de personas involucradas en el proceso*</Form.Label>
          <Form.Control
            required
            name="personas"
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder="Ingresa el número de personas involucradas en el proceso"
          />
        </Form.Group>
        </Row>
        <Row className="mb-f">
              {questions.map((question)=>(
                <div key ={"x"+question}>
                
                {personQuestion(question)}
                
                </div>
              ))}
        </Row>
        <Row className="mb-f">
          <Form.Group as={Col} controlId="formYears">
            <Form.Label>¿Por cuántos años crees que va a continuar realizando este proceso?*</Form.Label>
            <Form.Control
              required
              name="anos_seleccionados"
              type="number"
              min="0"
              onKeyPress={preventMinus}
              onChange={onFormChange}
              placeholder="Ingresa los años que crees que continuará realizando el proceso"
            />
          </Form.Group>

        <Form.Group as={Col} controlId="formInversion">
          <Form.Label>Valor inversión de la automatización (Sin IVA)</Form.Label>
          <Form.Control
            name="inversion_seleccionada"
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onChange={onFormChange}
            placeholder="Ingresa el valor de la inversión de la automatización"
          />
        </Form.Group>
      </Row>      
      
      {/* <Row className="mb-f">
        <Form.Group as={Col} controlId="formTtodatos" style={{ textAlign: "left" }}>
          <Form.Check
            required
            type="checkbox"
            label={
              <span>
                  Acepto {''}
                  <a href='https://www.danalyticspro.co/politica-de-privacidad-y-tratamiento-de-datos/' target="_blank" rel="noopener noreferrer">
                    política de tratamiento de datos personales*
                  </a>
              </span>
          }
          />
          </Form.Group>
        </Row>  */}

      <div className="mt-2">
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Realizando el cálculo
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="boton-primario"
          >
            Realizar cálculo
          </Button>
        )}
      </div>
    </Form>
  );
};

export default DatosComparacionForm;
