import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";

import CajaDescripcion from "../cajas/caja_descripcion";
import logo from "../../images/logo_nuevo_da.png";

const SeccionInicio = () => {
  const titulo =
    "El aliado de tu empresa en la automatización de procesos";
  const descripcion =
    "¿Quieres saber cuánto podría mejorar la gestión de tus recursos contratando con DAnalytics? ";
  const info_adicional = "";

  return (
    <section
      id="inicio"
      className="effect-section skew-effect primary-after bg-gray-100"
    >
      <div className="white-shade-effect"></div>
      <div className="container position-relative py-lg-8 z-index-9">
        <div className="row justify-content-center py-4">
          <div className="text-center" >
            <Image style={{maxHeight :"300px"}} src={logo} fluid />
          </div>

          <CajaDescripcion
            titulo={titulo}
            descripcion={descripcion}
            info_adicional={info_adicional}
          ></CajaDescripcion>
          <div className="col-8">
            <div className="text-center">
              {
                <Link
                  to="/comparacion"
                  className="btn btn-info btn-lg boton-primario"
                >
                  Quiero saber
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeccionInicio;
