export const tamanos = [
    {
    "nombre": "Pequeña",
    "valor": "P"
    },
    {
    "nombre": "Mediana",
    "valor": "M"
    },
    {
    "nombre": "Grande",
    "valor": "G"
    }
       ]