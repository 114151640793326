export const periodicidades = [
    {
    "nombre": "Diario",
    "valor": "Diario"
    },
    {
    "nombre": "Semanal",
    "valor": "Semanal"
    },
    {
    "nombre": "Quincenal",
    "valor": "Quincenal"
    },
    {
    "nombre": "Mensual",
    "valor": "Mensual"
    }
       ]