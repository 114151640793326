import React, { useState } from "react";
import { Row, Image, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import DatosComparacionForm from "../formularios/datos_pronostico";
import CajaDescripcion from "../cajas/caja_descripcion";
import logo from "../../images/logo_nuevo_da.png";

const SeccionComparacion = () => {
  const titulo_pronostico = "";
  const descripcion_pronostico =  ""
  const info_adicional = "Calcula cuánto tiempo y dinero podrías liberar de tareas empresariales repetitivas y periódicas diligenciando los siguientes datos.";
  const [mostrarExplicacion, setMostrarExplicacion] = useState(false);

  const toggleExplicacion = () => {
    setMostrarExplicacion(!mostrarExplicacion);
  };

  return (
    <div>
      <div className="white-shade-effect"></div>
      <div className="container position-relative z-index-9">
      <Row className="justify-content-center">
        <div className="text-center">
          <Image
            style={{ maxHeight: "120px", paddingBottom: "0" }}
            src={logo}
            fluid
          />
        </div>
        <CajaDescripcion
          titulo={titulo_pronostico}
          descripcion={descripcion_pronostico}
          info_adicional={info_adicional}
        />
        <div className="col-xl-12 col-lg-8 col-md-8 col-sm-10 col-xs-12 py-2 bg-white rounded shadow-style">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                fontSize: "20px",
                color: "#ccc",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={toggleExplicacion}
              title="Ver explicación del cálculo"
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            </div>
          </div>
          <Modal show={mostrarExplicacion} onHide={toggleExplicacion}>
            <Modal.Header>
              <Modal.Title>Explicación del cálculo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Para la realización del cálculo se toma en cuenta la periodicidad del proceso, el número de personas involucradas, su respectivo salario y las horas que invierte en el 
              proceso dependiendo del escenario (Optimista, real y pesimista), así como los años por los cuales cree que seguirá realizando el proceso y opcional la inversión que 
              realizará en la automatización. Posteriormente, se calcula el salario por hora dividiendo el salario entre la cantidad de horas trabajadas por mes, luego se toma 
              la cantidad de horas semanales que invierte en el proceso y se calcula el costo por hora, por mes y posteriormente por año. Finalmente, se calcula el costo estimado
              durante los años de ejecución del proceso en cada uno de los escenarios, teniendo en cuenta el crecimiento del salario mínimo en esos años y la diferencia entre ese 
              costo y la inversión de la automatización en caso de haber sido ingresada.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={toggleExplicacion}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="text-center">
            <DatosComparacionForm />
          </div>
        </div>
      </Row>

      </div>
    </div>
  );
};

export default SeccionComparacion;