export const diccionario_industrias =    {
      "Colombia": {
        "Automatización y électrico ": {
          "Account Manager": { "G": 7000000, "M": 5000000, "P": 3000000 },
          "Business Development Manager": {
            "G": 12000000,
            "M": 9000000,
            "P": 6000000
          },
          "Gerencia Comercial": { "G": 10000000, "M": 7000000, "P": 5000000 },
          "Gerencia de Producto": { "G": 12000000, "M": 7000000, "P": 5000000 },
          "Ingeniero(a) de Ventas": { "G": 7000000, "M": 5000000, "P": 3000000 }
        },
        "Automotriz y autopartes": {
          "Gerencia Comercial": { "G": 12000000, "M": 6000000, "P": 4000000 },
          "Gerencia de Mercadeo": { "G": 12000000, "M": 6000000, "P": 4000000 },
          "Gerencia de Producto": { "G": 10000000, "M": 7000000, "P": 4000000 },
          "Gerencia de Repuestos y Accesorios": {
            "G": 9000000,
            "M": 5000000,
            "P": 3000000
          },
          "Gerencia de Servicio/Postventa": {
            "G": 11000000,
            "M": 7000000,
            "P": 4000000
          },
          "Jefatura de Inteligencia de Negocios": {
            "G": 11000000,
            "M": 6000000,
            "P": 3000000
          },
          "KAM": { "G": 7000000, "M": 3500000, "P": 2500000 }
        },
        "Banca y servicios financieros ": {
          "Analista de Inversión": { "G": 4000000 },
          "Asociado de inversión": { "G": 8000000 },
          "Dirección de Crédito y Riesgo": { "G": 15000000 },
          "Dirección de banca de inversión": { "G": 16000000 },
          "Gerencia / Dirección de Operaciones": { "G": 15000000 },
          "Gerencia Comercial Banca": { "G": 13000000 },
          "Gerencia de canales ": { "G": 13000000 },
          "Gerencia de desarrollo de nuevos negocios ": { "G": 15000000 }
        },
        "Consumo Masivo": {
          "Ejecutivo de ventas ": { "G": 7000000, "M": 5000000, "P": 3000000 },
          "Gerencia comercial": { "G": 25000000, "M": 13000000, "P": 8000000 },
          "Gerencia de Mercadeo": { "G": 25000000, "M": 13000000, "P": 8000000 },
          "Jefatura / gerencia de Trade Marketing": {
            "G": 13000000,
            "M": 8000000,
            "P": 4000000
          },
          "Jefatura/gerencia de categoría": {
            "G": 20000000,
            "M": 13000000,
            "P": 9000000
          },
          "Jefatura/gerencia de marca": {
            "G": 14000000,
            "M": 10000000,
            "P": 6000000
          },
          "KAM": { "G": 13000000, "M": 8000000, "P": 4000000 }
        },
        "Energía (energía renovable, energía convencional, equipos)": {
          "Business Developer Manager": { "G": 18000000 },
          "Environmental & Permitting Coordinator": { "G": 18000000 },
          "Gerencia de Comercialización de Energía": { "G": 15000000 },
          "Gerencia de Control de Pérdidas": { "G": 23000000 },
          "Gerencia de Desarrollo y Construcción de Proyectos": { "G": 18000000 },
          "Gerencia de Regulación de Energía": { "G": 18000000 },
          "Gerencia de Sostenibilidad": { "G": 17000000 },
          "KAM energía": { "G": 10000000 },
          "Lider fotovoltaico ": { "G": 20000000 },
          "Project Manager": { "G": 18000000 }
        },
        "Finanzas y contabilidad": {
          "Accounting Controller": { "G": 13000000 },
          "Business Controller (FP&A)": { "G": 13000000 },
          "CFO": { "G": 22000000 },
          "Contador General / Gerencia Contable (Blingüe)": { "G": 13000000 },
          "Contador Senior Bilingüe": { "G": 6000000 },
          "Contador(a) Senior Especialista en Impuestos / Líder de Impuestos": {
            "G": 9000000
          },
          "Gerencia / Dirección de Auditoría Interna": { "G": 9000000 },
          "Gerencia / Dirección de FP&A": { "G": 13000000 },
          "Gerencia / Dirección de Impuestos (Bilingüe)": { "G": 14000000 },
          "Gerencia / Dirección de Tesorería": { "G": 11000000 },
          "Gerencia Administrativa y Financiera": { "G": 14000000 },
          "Gerencia Contable (No Bilingüe)": { "G": 6000000 },
          "Gerencia Financiera / Finance Controller": { "G": 13000000 },
          "Gerencia de Crédito y Cartera": { "G": 10000000 },
          "Jefatura Financiera y Administrativa": { "G": 8000000 },
          "Líder / Coordinación de Crédito y Cartera": { "G": 6000000 },
          "Líder / Especialista / Coordinación de Tesorería (Bilingüe)": {
            "G": 8000000
          },
          "Líder de Auditoría Interna / Auditor(a) Interno": { "G": 6000000 },
          "Líder/ Especialista / Coordinación de FP&A": { "G": 8000000 }
        },
        "Flores ": {
          "Ejecutivo comercial ": { "G": 8000000, "M": 6000000, "P": 4000000 },
          "Gerencia comercial ": { "G": 13000000, "M": 8000000, "P": 5000000 },
          "Gerencia de producción": { "G": 14000000, "M": 9000000, "P": 5000000 },
          "Jefatura de área/finca": { "G": 6000000, "M": 5000000, "P": 4000000 },
          "Jefe de Postcosecha ": { "G": 8000000, "M": 6000000, "P": 4000000 }
        },
        "Industria (bombas y valvulas, equipos rotativos, compresores y bohilers)": {
          "Business Development Manager": {
            "G": 19000000,
            "M": 14000000,
            "P": 7000000
          },
          "Gerencia de producto": { "G": 9000000, "M": 7000000, "P": 4000000 },
          "Gerencial Comercial": { "G": 19000000, "M": 14000000, "P": 7000000 },
          "Ingeniero(a) de Aplicaciones": {
            "G": 5000000,
            "M": 4000000,
            "P": 2000000
          },
          "Ingeniero(a) de Ventas": { "G": 6000000, "M": 5000000, "P": 3000000 },
          "Ingeniero(a) de Ventas Senior": {
            "G": 8000000,
            "M": 6000000,
            "P": 4000000
          },
          "KAM": { "G": 9000000, "M": 7000000, "P": 5000000 }
        },
        "Industria Maquinaria ( Maquinaria pesada, maquinaria liviana, herrmientas)": {
          "Asesor(a) Comercial": { "G": 6000000, "M": 4000000, "P": 3000000 },
          "Business Development Manager": {
            "G": 18000000,
            "M": 13000000,
            "P": 8000000
          },
          "Gerencia Comercial": { "G": 19000000, "M": 13000000, "P": 7000000 },
          "Gerencia de Producto": { "G": 8000000, "M": 6000000, "P": 4000000 },
          "Ingeniero(a) de Ventas Senior": {
            "G": 9000000,
            "M": 7000000,
            "P": 5000000
          },
          "KAM": { "G": 10000000, "M": 8000000, "P": 6000000 }
        },
        "Ingeniería y manufactura (sector industrial, material de construcción, sector farmacéutica)": {
          "Gerencia de Calidad": { "G": 13000000 },
          "Gerencia de HSE": { "G": 18000000 },
          "Gerencia de Mantenimiento": { "G": 13000000 },
          "Gerencia de Operaciones / Manufactura": { "G": 24000000 },
          "Gerencia de Planta / Producción": { "G": 18000000 },
          "Jefatura de Calidad": { "G": 8000000 },
          "Jefatura de HSE": { "G": 8000000 },
          "Jefatura de Mantenimiento": { "G": 8000000 },
          "Jefatura de Mejora Continua": { "G": 8000000 },
          "Jefatura de Planeacion y Confiabilidad de Mantenimiento": {
            "G": 8000000
          },
          "Jefatura de Planeación de la Producción": { "G": 8000000 },
          "Jefatura de Planta / Producción": { "G": 8000000 },
          "Jefatura de proyectos": { "G": 8000000 }
        },
        "Insumos agricolas ": {
          "Gerencia comercial ": { "G": 13000000, "M": 8000000, "P": 5000000 },
          "Gerencia de Mercadeo": { "G": 13000000, "M": 8000000, "P": 5000000 },
          "Jefatura de Zona ": { "G": 8000000, "M": 5000000, "P": 3000000 },
          "Representante tecnico comercial ": {
            "G": 6000000,
            "M": 3000000,
            "P": 1500000
          }
        },
        "Legal - Firma de abogados ": {
          "Asociado Intermedio": { "G": 8000000 },
          "Asociado Principal": { "G": 17000000 },
          "Asociado Senior": { "G": 13000000 },
          "Dirección": { "G": 25000000 }
        },
        "Legal - Sector Real ": {
          "Abogado Laboralista": { "G": 7000000 },
          "Gerencia Legal": { "G": 25000000 },
          "Legal Counsel": { "G": 13000000 },
          "Oficial de Cumplimento": { "G": 17000000 }
        },
        "Logistica ": {
          "Ejecutivo Comercial Junior": { "G": 6000000 },
          "Ejecutivo Comercial Senior": { "G": 6000000 },
          "Gerencia / Jefatura de Proyectos y Diseño": { "G": 8000000 },
          "Gerencia / Jefatura de transporte Última Milla": { "G": 13000000 },
          "Gerencia Comercial": { "G": 14000000 },
          "Gerencia de Abastecimiento / Compras": { "G": 13000000 },
          "Gerencia de Almacenamiento": { "G": 13000000 },
          "Gerencia de CEDI": { "G": 15000000 },
          "Gerencia de Distribución y Transporte": { "G": 13000000 },
          "Gerencia de Logística": { "G": 15000000 },
          "Gerencia de Operaciones / Supply Chain": { "G": 18000000 },
          "Gerencia de Planeación de Demanda (S&OP)": { "G": 15000000 },
          "Gerencia de Transporte": { "G": 13000000 },
          "Gerencia de Unidad": { "G": 15000000 },
          "Inside Sales": { "G": 3000000 },
          "Jefatura Comercial": { "G": 8000000 },
          "Jefatura de Almacenamiento": { "G": 8000000 },
          "Jefatura de Comex": { "G": 7000000 },
          "Jefatura de Compras": { "G": 7000000 },
          "Jefatura de Distribución y Transporte": { "G": 8000000 },
          "Jefatura de Logística": { "G": 8000000 },
          "Jefatura de Operaciones": { "G": 10000000 },
          "Jefatura de Planeación de Demanda (S&OP)": { "G": 9000000 },
          "Jefatura de Transporte": { "G": 8000000 },
          "KAM": { "G": 6000000 },
          "Pricing Analyst": { "G": 3000000 },
          "Supervisor de Trucking": { "G": 5000000 }
        },
        "Materiales de construcción": {
          "Especificador": { "G": 7500000, "M": 6500000, "P": 4000000 },
          "Gerencia de Mercadeo": { "G": 18000000, "M": 14000000, "P": 10000000 },
          "Gerencia de Producto": { "G": 9000000, "M": 8000000, "P": 6000000 },
          "Gerencia de Ventas": { "G": 18000000, "M": 14000000, "P": 10000000 },
          "Ingeniero(a) de Ventas": { "G": 6000000, "M": 5000000, "P": 4000000 },
          "Ingeniero(a) de Ventas Senior": {
            "G": 9000000,
            "M": 7500000,
            "P": 5500000
          },
          "KAM": { "G": 11000000, "M": 9000000, "P": 7000000 }
        },
        "Minería": {
          "Gerencia Ambiental": { "G": 22000000 },
          "Gerencia de Calidad": { "G": 18000000 },
          "Gerencia de HSEQ": { "G": 22000000 },
          "Gerencia de Innovación": { "G": 18000000 },
          "Gerencia de Mina": { "G": 28000000 },
          "Gerencia de Operaciones": { "G": 18000000 },
          "Gerencia de Planeación Minera": { "G": 18000000 },
          "Gerencia de Producción": { "G": 18000000 },
          "Gerencia de Proyectos": { "G": 22000000 },
          "Gerencia de Sostenibilidad / Gerencia Ambiental": { "G": 18000000 }
        },
        "OIL & gas": {
          "Geofísico": { "G": 15000000 },
          "Gerencia Ambiental": { "G": 14000000 },
          "Gerencia HSEQ": { "G": 12000000 },
          "Gerencia de Mantenimiento": { "G": 18000000 },
          "Gerencia de Perforación Offshore": { "G": 65000000 },
          "Geólogo para Yacimientos No Convecionales": { "G": 18000000 },
          "Ingeniero(a) Tratamiento de Agua o Crudo": { "G": 10000000 },
          "Ingeniero(a) de ALS y / o Bombeo Mecánico": { "G": 14000000 },
          "KAM de Servicio Petroleros": { "G": 15000000 },
          "Project Manager": { "G": 12000000 }
        },
        "Otros productores agrícolas ": {
          "Agronomo": { "G": 6000000, "M": 4000000, "P": 2500000 },
          "Dirección de planta extractora ": {
            "G": 13000000,
            "M": 8000000,
            "P": 5000000
          },
          "Dirección de plantación ": {
            "G": 13000000,
            "M": 8000000,
            "P": 5000000
          }
        },
        "Propiedad en construcción ": {
          "BIM Manager": { "G": 8000000 },
          "Dirección de Obra": { "G": 8000000 },
          "Gerencia Comercial": { "G": 8000000 },
          "Gerencia de Estructuración de Negocios": { "G": 13000000 },
          "Gerencia de Proyectos": { "G": 13000000 }
        },
        "Químicos y materias primas ": {
          "Especialista de Investigación y Desarrollo": {
            "G": 8000000,
            "M": 6000000,
            "P": 4000000
          },
          "Gerencia Comercial": { "G": 25000000, "M": 15000000, "P": 10000000 },
          "Gerencia de Investigación y Desarrollo": {
            "G": 18000000,
            "M": 13000000,
            "P": 10000000
          },
          "Gerencia de Mercadeo": { "G": 25000000, "M": 15000000, "P": 10000000 },
          "Ingeniero(a) de Ventas": { "G": 8000000, "M": 6000000, "P": 4000000 },
          "KAM": { "G": 13000000, "M": 8000000, "P": 6000000 }
        },
        "Recursos humanos (BPO, TECNOLOGÍA, MANUFACTURA) ": {
          "Dirección de Recursos Humanos": { "G": 22000000 },
          "Gerencia de Compensación y Beneficios": { "G": 13000000 },
          "Gerencia de Formación y Desarrollo": { "G": 15000000 },
          "Gerencia de Recursos Humanos": { "G": 13000000 },
          "Human Resources Business Partner": { "G": 9000000 },
          "Recruiter": { "G": 7000000 },
          "Talent Acquisition Manager": { "G": 13000000 }
        },
        "Retail": {
          "Category Managers ": { "G": 13000000, "M": 9000000, "P": 7000000 },
          "Gerencia comercial": { "G": 28000000, "M": 18000000, "P": 11000000 },
          "Gerencia de Mercadeo": { "G": 28000000, "M": 18000000, "P": 11000000 },
          "Gerencia de operaciones": {
            "G": 25000000,
            "M": 15000000,
            "P": 8000000
          },
          "Gerencia de producto": { "G": 13000000, "M": 9000000, "P": 7000000 },
          "Gerencia de tiendas /zonas ": {
            "G": 8000000,
            "M": 6000000,
            "P": 4000000
          },
          "Jefatura/gerencia de expansión": {
            "G": 18000000,
            "M": 13000000,
            "P": 8000000
          }
        },
        "Salud y ciencias de la vida (Dispositivos medicos)": {
          "Business Unit Manager": { "G": 20000000 },
          "Coordinación / Especialista de Asuntos Regulatorios": { "G": 7000000 },
          "Especialista de Producto / Aplicaciones": { "G": 6000000 },
          "Gerencia Comercial": { "G": 16000000 },
          "Gerencia de Asuntos Regulatorios": { "G": 14000000 },
          "Gerencia de Distribuidores": { "G": 13000000 },
          "Gerencia de Línea": { "G": 17000000 },
          "Gerencia de Mercadeo": { "G": 16000000 },
          "Gerencia de Nuevos Negocios / Business Development Manager": {
            "G": 15000000
          },
          "Gerencia de Servicio": { "G": 12500000 },
          "Key Account Manager": { "G": 8000000 },
          "Representante de Ventas": { "G": 4000000 }
        },
        "Salud y ciencias de la vida (Industria farmacéutica)": {
          "Business Intelligence Manager/ Gerencia de Excelencia Comercial": {
            "G": 12000000
          },
          "Dirección / Gerencia Comercial": { "G": 22000000 },
          "Dirección / Gerencia de Acceso": { "G": 25000000 },
          "Dirección Médica": { "G": 24000000 },
          "Dirección Técnica, Regulatoria y de Calidad": { "G": 14000000 },
          "Especialista de Asuntos Regulatorios": { "G": 13000000 },
          "Gerencia Médica": { "G": 20000000 },
          "Gerencia de Asuntos Regulatorios": { "G": 19000000 },
          "Gerencia de Desarrollo de Nuevos Negocios": { "G": 18000000 },
          "Gerencia de Distrito - Gerencia Regional": { "G": 12000000 },
          "Gerencia de Línea / Gerencia de Producto": { "G": 18000000 },
          "Gerencia de Marketing": { "G": 22000000 },
          "Gerencia de Promoción": { "G": 16000000 },
          "KAM Acceso": { "G": 9000000 },
          "KAM Comercial": { "G": 7000000 },
          "KAM Trade": { "G": 6000000 },
          "MSL": { "G": 13000000 },
          "Patient Support Program Manager/ Patient Advocacy Man_x0002_ager": {
            "G": 15000000
          },
          "Pricing and Health Economics Manager": { "G": 15000000 },
          "Value Proposition Manager": { "G": 18000000 }
        },
        "Salud y nutrición animal/agropecuario ": {
          "Gerencia comercial ": { "G": 15000000, "M": 9000000, "P": 6000000 },
          "Gerencia unidad de negocio ": {
            "G": 18000000,
            "M": 9000000,
            "P": 7000000
          },
          "KAM": { "G": 8000000, "M": 6000000, "P": 4500000 }
        },
        "Seguros": {
          "Actuario Junior": { "G": 5000000 },
          "Actuario Senior": { "G": 13000000 },
          "Dirección Comercial Seguros": { "G": 18000000 },
          "Gerencia Comercial": { "G": 13000000 },
          "Gerencia de Suscripción": { "G": 15000000 }
        }
      }
    }

  
  