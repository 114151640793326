import { Image } from "react-bootstrap";

import FooterTerminos from "../components/secciones/seccion_footer";
import img404 from "../images/logo.png";

const NotFound = () => {
  return (
    <div>
      <main>
        <section id="comparacion" className="effect-section bg-gray-100 ">
          <div className="text-center">
            <Image
              style={{ maxHeight: "500px" }}
              src={img404}
              alt="404"
              fluid
            />
            <h1>Página no encontrada</h1>
          </div>
        </section>
      </main>
      <FooterTerminos />
    </div>
  );
};

export default NotFound;
