import React from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import CajaDescripcion from "../cajas/caja_descripcion";


const SeccionResultados = () => {
  const { state } = useLocation();

  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


  var valor_optimista = currencyFormat(state.valor_optimista);
  var valor_real = currencyFormat(state.valor_real);
  var valor_pesimista = currencyFormat(state.valor_pesimista);
  var horas_optimista = state.horas_optimista;
  var horas_real = state.horas_real;
  var horas_pesimista = state.horas_pesimista;
  var ganancias_optimista = currencyFormat(state.ganancias_optimista);
  var ganancias_real = currencyFormat(state.ganancias_real);
  var ganancias_pesimista = currencyFormat(state.ganancias_pesimista);
  var anos = state.anos;
  var inversion = currencyFormat(state.inversion);
  const titulo_pronostico = "Resultados";

  
  return (
    <div>
      <div className="white-shade-effect"></div>
      <Container className="position-relative py-lg-8 z-index-9">
        <Row className="justify-content-center">
        <CajaDescripcion
            titulo={titulo_pronostico}
            
          ></CajaDescripcion>
        </Row>
        <div className="col-xl-12 col-lg-8 col-md-8 col-sm-10 col-xs-12 py-2 bg-white rounded shadow-style">
          <div className="text-center">
            <div className="mt-2 mb-3 me-4 ms-4">
            <Table responsive>
  <thead>
    <tr>
      <th>Escenario</th>
      <th>Costo sin la automatización</th>
      {inversion !== "$0.00" && <th>Inversión de la automatización</th>}
      {inversion !== "$0.00" && <th>Ganancia o pérdida de la inversión</th>}
      <th>Horas liberadas</th>
    </tr>
  </thead>
  <tbody>
    {valor_optimista !== "$0.00" && (
      <tr>
        <td>Optimista</td>
        <td>{valor_optimista}</td>
        {inversion !== "$0.00" && <td>{inversion}</td>}
        {inversion !== "$0.00" && <td>{ganancias_optimista}</td>}
        <td>{horas_optimista}</td>
      </tr>
    )}
    <tr>
      <td>Promedio</td>
      <td>{valor_real}</td>
      {inversion !== "$0.00" && <td>{inversion}</td>}
      {inversion !== "$0.00" && <td>{ganancias_real}</td>}
      <td>{horas_real}</td>
    </tr>
    {valor_pesimista !== "$0.00" && (
      <tr>
        <td>Pesimista</td>
        <td>{valor_pesimista}</td>
        {inversion !== "$0.00" && <td>{inversion}</td>}
        {inversion !== "$0.00" && <td>{ganancias_pesimista}</td>}
        <td>{horas_pesimista}</td>
      </tr>
    )}
  </tbody>
</Table>

            </div>
            <Row className="py-2 justify-content-center">
              <p className="text-center">
                <h3>
                ¿Imaginas lo que tu organización podría lograr con {horas_real} horas libres en los próximos {anos} años? Descubre cómo maximizar el valor de tu tiempo y recursos con soluciones de automatización empresarial.  
                </h3>
              </p>
            </Row>
            <div className="mt-1 mb-2 text-center">
              <a
                href="/comparacion"
                className="btn btn-primary"
                style={{ backgroundColor: "#DC413A", borderColor: "#DC413A" }}
              >
                Recalcular
              </a>
            </div>
            
          </div>
        </div>
        
      </Container>
    </div>
  );
};

export default SeccionResultados;
